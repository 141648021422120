import React from 'react';
import './About.css';
import Title from '../Title/Title'; // Reuse the Title component for the heading
import aboutUsImage from '../../Resource/aboutus.jpg'; // Replace with your image


const About = () => {
    return (
        <section className="about-us-card-container">
            <div className="about-us-card">
                <div className="about-us-image">
                    <img src={aboutUsImage} alt="About Us" />
                </div>
                <div className="about-us-content">
                    {/* <h2 className="about-us-title">About Innodhee</h2> */}
                    <p>
                        Innodhee Solutions is an IT solution provider specializing in advanced integration solutions for both SAP and non-SAP systems. With a focus on enabling seamless communication and data flow across diverse enterprise environments, Innodhee Solutions empowers businesses to optimize their operations through robust integration frameworks and innovative automation tools.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;


