import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import logo from '../../Resource/logo.png';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import './VerificationPage.css';

const VerificationPage = () => {
    const [searchParams] = useSearchParams();
    const verificationCode = searchParams.get('code');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const navigate = useNavigate();
    const apiCalled = useRef(false);
    useEffect(() => {
        const VerificationApi = async (code) => {
            try {
                const requestBody = {
                    ActivationCode: code
                };
                setLoading(true);
                setMessage("Please Wait, Logging...")
                const response = await AxiosInstance.post('/Verify', requestBody); // Replace with actual API endpoint
                setLoading(false);
                // Extract the message and reason from API response
                const { message, reason } = response.data || {};

                // Set state with the message and verification status
                if (message === "success") {
                    //console.log("Verification: Response message ", reason)
                    navigate('/VerificationCompleted', { state: { message, reason } });
                } else {
                    //console.log("Verification: Response message ", reason)
                    navigate('/VerificationCompleted', { state: { message, reason } });
                }
            } catch (error) {
                //console.log("Verification: Response message ", error)
                navigate('/VerificationCompleted', { state: { message: "error", reason: "Something Went Wrong, Please Try Again." } });
            }
        };

        if (verificationCode && !apiCalled.current) {
            apiCalled.current = true;
            VerificationApi(verificationCode);
        }
    }, [verificationCode, navigate]); // Ensure `navigate` is included in the dependency array

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
        </div>
        // <div className="modal">
        //     <div className="loading-content">
        //         <img src={logo} class="spinnerlogo" alt="Logo" />
        //         <div className="loading-text">Please Wait, Loading...</div>
        //     </div>
        // </div>
    );
};

export default VerificationPage;
