import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginPage from './Pages/Login/LoginPage';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { AlertProvider } from './ProHelpers/AlertContext';
import RegistrationComplete from './Pages/Registration/RegistrationComplete';
import VerificationPage from './Pages/Registration/VerificationPage';
import VerificationCompleted from './Pages/Registration/VerificationCompleted'
import Dashboard from './Pages/Dashboard/Dashboard';
import ForgotPassword from './Pages/ForgetPassword/ForgotPassword'
import ForgotVerificationPage from './Pages/ForgetPassword/ForgotVerificationPage';
import ForgotPasswordSet from './Pages/ForgetPassword/ForgotPasswordSet';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Login",
    element: <LoginPage />,
  }, {
    path: "/Register",
    element: <RegistrationPage />,
  }, {
    path: "/RegistrationCompleted",
    element: <RegistrationComplete />,
  }, {
    path: "/Verification",
    element: <VerificationPage />,
  }, {
    path: "/VerificationCompleted",
    element: <VerificationCompleted />,
  }, {
    path: "/Dashboard",
    element: <Dashboard />,
  }, {
    path: "/ForgotPassword",
    element: <ForgotPassword />,
  }, {
    path: "/ForgotVerification",
    element: <ForgotVerificationPage />,
  }, {
    path: "/ForgotPasswordSet",
    element: <ForgotPasswordSet />
  }
  //  {
  //   path: "IntegrationService",
  //   element: <IntegrationSolution />,
  // }, {
  //   path: "AutomationTools",
  //   element: <AutomationTools />,
  // }, {
  //   path: "ComprehensiveResourcing",
  //   element: <ComprehensiveResourcing />,
  // }
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AlertProvider>
      <RouterProvider router={router} />
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
