import React from 'react';

const TruncatedText = ({ text, limit }) => {
    // Function to truncate the text to a specified character limit
    const truncateText = (text, limit) => {
        if (text.length > limit) {
            return text.substring(0, limit) + " ...";
        }
        return text;
    };

    return (
        <p>{truncateText(text, limit)}</p>
    );
};

export default TruncatedText;
