import React, { useState } from 'react';
import './LoginPage.css';
import loginImage from '../../Resource/loginImage.png'; // Add your image here
import { Link } from 'react-router-dom'; // Import Link for navigation
import openEye from '../../Resource/openeye.jpeg';
import closeEye from '../../Resource/closeeye.jpeg';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import { useNavigate } from 'react-router-dom';
import useCheckAuth from '../../ProHelpers/CheckAuth';
import Logo from '../../ProHelpers/Logo'

const LoginPage = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [email, setEmail] = useState(''); // State for email
    const [password, setPassword] = useState(''); // State for password
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    useCheckAuth();
    const LoginApi = async (email, password) => {
        try {
            const requestBody = {
                EmailID: email,
                CustomerPassword: password
            };
            setLoading(true);
            setMessage("Please Wait, Logging...")
            const response = await AxiosInstance.post('/Login', requestBody); // Replace with actual API endpoint
            setLoading(false);
            const { message, reason, details } = response.data || {}; // Ensure 'data' exists
            //console.log("Verification: Response message ", message)
            if (message === "success") {
                console.log("Verification: Response message ", response.data)
                sessionStorage.setItem('CustomerID', details.CustomerID);
                sessionStorage.setItem('EmailID', details.EmailID);
                const UserName = details.FirstName + ' ' + details.LastName;
                sessionStorage.setItem('UserName', UserName);
                sessionStorage.setItem('AccountType', details.AccountType);
                navigate('/Dashboard', { state: { message, reason } });
            } else {
                console.log("Verification: Response message ", response.data);
                setTimeout(() => showAlert(message, 'failure'), 0);
            }
        } catch (error) {
            setLoading(false);
            //console.log("Error fetching data from API:", error);
            return { message: "error", reason: "API request failed" };
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        LoginApi(email, password); // Call the LoginApi function
    };

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
            <div className="login-page">
                <div className="login-card">
                    <div className="login-card-left">
                        <img src={loginImage} alt="Login" className="login-image" />
                        <Link to="/">
                            {/* <img src={logo} alt="Logo" className="logo" /> */}
                            <Logo className="logo" />
                        </Link>
                    </div>
                    <div className="login-card-right">
                        <h2 className="login-title">Sign In</h2>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} // Update email state
                                    required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="password">Password</label>
                                <div className="password-wrapper">
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        id="password"
                                        name="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} // Update password state
                                        required />
                                    <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                                        {passwordVisible ? (
                                            <img src={openEye} alt="Hide Password" />
                                        ) : (
                                            <img src={closeEye} alt="Show Password" />
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="login-btn-container">
                                <button type="submit" className="btn-submit">Login</button>
                            </div>
                        </form>
                        <div className="register-link">
                            <p>Don't have an account? <Link to="/Register">Create account</Link>|<Link to="/ForgotPassword">Forgot Password</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;

