import React, { useState } from 'react'
import './ForgotPassword.css';
import forgotImage from '../../Resource/forgotpassword.png'; // Add your image here
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import Logo from '../../ProHelpers/Logo'

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [email, setEmail] = useState(''); // State for email

    // Function to handle input change
    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const ForgotEmail = async (email) => {
        try {
            const requestBody = {
                EmailID: email
            };
            setLoading(true);
            setMessage("Please Wait, Logging...")
            const response = await AxiosInstance.post('/ForgotPassword', requestBody);
            setLoading(false);
            const { message, status } = response.data || {};
            if (status === "success") {
                console.log("Verification: Response message ", message)
                setEmail('')
                setTimeout(() => showAlert(message, status), 0);
                //navigate('/Dashboard', { state: { message, reason } });
            } else {
                console.log("Verification: Response message ", message);
                setTimeout(() => showAlert(message, 'failure'), 0);
            }
        } catch (error) {
            setLoading(false);
            console.log("Error fetching data from API:", error);
            return { message: "error", reason: "API request failed" };
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        ForgotEmail(email); // Call the LoginApi function
    };

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
            <div className="forgot-page">
                <div className="forgot-card">
                    <div className="forgot-card-left">
                        <img src={forgotImage} alt="Login" className="forgot-image" />
                        <Link to="/">
                            {/* <img src={logo} alt="Logo" className="logo" /> */}
                            <Logo className="logo" />
                        </Link>
                    </div>
                    <div className="forgot-card-right">
                        <h2 className="forgot-title">Forgot Your Password?</h2>
                        <p>We get it, stuff happens. Just enter your email address</p>
                        <form className="forgot-form" onSubmit={handleSubmit}>
                            <div className="input-group">
                                <br></br><br></br>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleInputChange} // Update email state
                                    required />
                            </div>
                            <div className="forgot-btn-container">
                                <button type="submit" className="btn-submit">Reset Password</button>
                            </div>
                        </form>
                        <div className="register-link">
                            <p>Already have an account? <Link to="/Register">Create account</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword

