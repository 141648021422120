import React from 'react';
import './WhyUsSection.css';
import whyUsImage from '../../Resource/whyus.jpg'; // Assuming the image path is correct


const WhyUsSection = () => {
    return (
        <section className="why-us-card-container">
            <div className="why-us-card">
                <div className="why-us-image">
                    <img src={whyUsImage} alt="Why Us" />
                </div>
                <div className="why-us-content">
                    <div className="why-us-description">
                        <div className="why-us-item">
                            <h3>Expertise</h3>
                            <p>With a deep understanding of both SAP and non-SAP environments, Innodhee Solutions is equipped to handle complex integration challenges.</p>
                        </div>
                        <div className="why-us-item">
                            <h3>Innovation</h3>
                            <p>The company is committed to innovation, continually developing new products and frameworks that help businesses stay ahead of the curve.</p>
                        </div>
                        <div className="why-us-item">
                            <h3>Reliability</h3>
                            <p> Innodhee Solutions’ automation tools ensure reliable system monitoring, freeing up IT resources for more strategic tasks.</p>
                        </div>
                        <div className="why-us-item">
                            <h3>Customization</h3>
                            <p>All solutions are highly customizable, ensuring they meet the specific needs and goals of each client.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyUsSection;


