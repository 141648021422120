import React, { useState } from 'react';
import './Navbar.css';
import logo from '../../Resource/innodhee_logo.png'; // Add your logo image here
import menu_icon from '../../Resource/side-menu.png'; // Add your logo image here
import { Link } from 'react-router-dom';
import Logo from '../../ProHelpers/Logo'


const Navbar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <nav className="navbar">
            <div className="logo-container">
                <Link to="/">
                    <Logo className="logonav" />
                    {/* <img src={logo} alt="logo" className="logonav" /> */}
                </Link>
            </div>
            <ul className={`nav-links ${isSidebarOpen ? 'show-sidebar' : ''}`}>
                <li><a href="#hero-section">Home</a></li>
                <li><a href="#about-section">About</a></li>
                <li><a href="#services-section">Services</a></li>
                <li><a href="#whyus-section">Why Us</a></li>
                <li><a href="#mission-section">Mission</a></li>
                {/* <li><a href="#products-section">Products</a></li> */}
                {/* <li><a href="#testimonials-section">Testimonials</a></li> */}
                <li><a href="#contact-section">Contact</a></li>
                <li><Link to="/Login"><button className="login-btn" disabled={true}>Login</button></Link></li>
            </ul>
            <button className="navbar-icon mobile-menu-icon" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                {isSidebarOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
            </button>
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <button className="sidebar-close" onClick={() => setIsSidebarOpen(false)}>
                    <i className="fas fa-times"></i>
                </button>
                <ul className="sidebar-links">
                    <li><a href="#hero-section">Home</a></li>
                    <li><a href="#about-section">About</a></li>
                    <li><a href="#services-section">Services</a></li>
                    <li><a href="#whyus-section">Why Us</a></li>
                    <li><a href="#mission-section">Mission</a></li>
                    <li><Link to="/Login"><button className="login-btn" disabled={true}>Login</button></Link></li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;


