import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import forgotImage from '../../Resource/forgotpassword.png'; // Add your image here
import { Link } from 'react-router-dom';
import { useAlert } from '../../ProHelpers/AlertContext';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import { hashPassword } from '../../ProHelpers/AuthUtils';
import './ForgotPasswordSet.css';
import Logo from '../../ProHelpers/Logo'

function ForgotPasswordSet() {
    const location = useLocation();
    const { state } = location;
    const [loading, setLoading] = useState(false);
    const { status, message, reason } = state || {};
    const { showAlert } = useAlert();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isUpdated, setisUpdated] = useState(false);

    const passwordRequirements = (
        <div className="password-requirements">
            <p>Password must include:</p>
            <ul>
                <li>At least 8 characters</li>
                <li>One uppercase letter</li>
                <li>One lowercase letter</li>
                <li>One number</li>
                <li>One special character</li>
            </ul>
        </div>
    );

    // Function to validate password
    const validatePassword = (password) => {
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        return pattern.test(password);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    useEffect(() => {
        if (status === "success" && !isUpdated) {
            const isPasswordValid = validatePassword(password);
            const passwordsMatch = password === confirmPassword;
            const isPasswordNonEmpty = password !== '';
            const isConfirmPasswordNonEmpty = confirmPassword !== '';

            if (!isPasswordValid) {
                setTimeout(() => showAlert(passwordRequirements, 'warning'), 0);
                setIsFormValid(false);
            }
            else if (isPasswordValid && passwordsMatch && isPasswordNonEmpty && isConfirmPasswordNonEmpty) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        }
    }, [password, confirmPassword]);

    const ResetPassword = async (code, confirmpassword) => {
        try {
            const hashedPassword = await hashPassword(confirmpassword);
            const requestBody = {
                ActivationCode: code,
                CustomerPassword: hashedPassword
            };
            setLoading(true);
            const response = await AxiosInstance.post('/ResetPassword', requestBody);
            setLoading(false);
            const { message, reason, status } = response.data || {};
            console.log("ForgotPasswordSet: Response message ", response.data)
            setisUpdated(true)
            if (status === "success") {
                setPassword('');
                setConfirmPassword('');
                setTimeout(() => showAlert(message, status), 0);
            } else {
                setTimeout(() => showAlert(message, 'failure'), 0);
            }
        } catch (error) {
            setLoading(false);
            return { message: "error", reason: "API request failed" };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid) {
            ResetPassword(message.ActivationCode, confirmPassword);
        }
    };

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
            <div className="forgot-page">
                <div className="forgot-card">
                    <div className="forgot-card-left">
                        <img src={forgotImage} alt="Login" className="forgot-image" />
                        <Link to="/">
                            {/* <img src={logo} alt="Logo" className="logo" /> */}
                            <Logo className="logo" />
                        </Link>
                    </div>
                    <div className="forgot-card-right">
                        <h2 className="forgot-title">Reset Your Password</h2>

                        {/* Check the status to conditionally render the content */}
                        {status === "success" ? (
                            <form className="forgot-form" onSubmit={handleSubmit}>
                                <div className="input-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        placeholder="Enter your new password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="confirm-password">Confirm Password</label>
                                    <input
                                        type="password"
                                        id="confirm-password"
                                        placeholder="Confirm your new password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        required
                                    />
                                </div>
                                <div className="forgot-btn-container">
                                    <button type="submit" className="btn-submit" disabled={!isFormValid}>
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                        ) : (
                            // Show the reason if status is "failed"
                            <div className="reason-message">
                                <p>{message}</p>
                            </div>
                        )}

                        <div className="register-link">
                            <p>Already have an account? <Link to="/Login">Login</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordSet;
