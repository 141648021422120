
import React from 'react';
import logo from '../Resource/innodhee_gre_logo.png';

const Logo = ({ className }) => {
    return (
        <img src={logo} alt="Website Logo" className={className} />
    );
};

export default Logo;
