import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import './ForgotVerificationPage.css';

const ForgotVerificationPage = () => {
    const [searchParams] = useSearchParams();
    const verificationCode = searchParams.get('code');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const navigate = useNavigate();
    const apiCalled = useRef(false);
    useEffect(() => {
        const VerificationApi = async (code) => {
            try {
                const requestBody = {
                    ActivationCode: code,
                    ForgotPassword: 'True'
                };
                setLoading(true);
                setMessage("Please Wait, Logging...")
                const response = await AxiosInstance.post('/Verify', requestBody);
                setLoading(false);
                console.log("ForgotVerify: Response message ", response)
                // Extract the message and reason from API response
                const { message, reason, status } = response.data || {};

                // Set state with the message and verification status
                // if (status === "success") {
                //     //console.log("Verification: Response message ", reason)
                //     navigate('/ForgotPasswordSet', { state: { status, message, reason } });
                // } else {
                //console.log("Verification: Response message ", response.data)
                navigate('/ForgotPasswordSet', { state: { status, message, reason } });
                //}
            } catch (error) {
                setLoading(false);
                console.log("Verification: Response message ", error)
                // navigate('/VerificationCompleted', { state: { message: "error", reason: "Something Went Wrong, Please Try Again." } });
            }
        };

        if (verificationCode && !apiCalled.current) {
            apiCalled.current = true;
            VerificationApi(verificationCode);
        }
    }, [verificationCode, navigate]); // Ensure `navigate` is included in the dependency array

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
        </div>

    );
};

export default ForgotVerificationPage;
