import React from 'react';
import './Footer.css';
import logo from '../../Resource/innodhee_gre_logo.png'; // Add your logo image here

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            {/* Left Side: Icon and Text */}
            <div className="footer-left">
                <img src={logo} alt="logofooter" className="logofooter" />
                <span className="footer-text">
                    Copyright © INNODHEE {`2023 - ${currentYear}`}
                </span>
            </div>

            {/* Right Side: Links */}
            <div className="footer-right">
                <a href="#" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                <a href="#" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <a href="#" target="_blank" rel="noopener noreferrer">Disclaimer</a>
                <a href="#" target="_blank" rel="noopener noreferrer">Innovation Consulting</a>
            </div>
        </footer>
    );
};

export default Footer;
