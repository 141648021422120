import React from 'react'
import { useLocation } from 'react-router-dom';
import '../Login/LoginPage.css';
import { Link } from 'react-router-dom'; // Import Link for navigation
import logo from '../../Resource/logo.png'; // Add your logo image here
import mailsent from '../../Resource/MailSent.png'; // Add your image here

function RegistrationComplete() {
    const location = useLocation();
    const receivedData = location.state;
    return (
        <div className="login-page">
            <div className="completed-card">
                <div className="completed-card-left">
                    <img src={mailsent} alt="Completed" className="completed-image" />
                    <Link to="/"><img src={logo} alt="Logo" className="logo" /></Link>
                </div>
                <div className="login-card-right">
                    <h2 className="login-title">Completed</h2>
                    <div className="input-group">
                        <div className="completed-wrapper">
                            <p dangerouslySetInnerHTML={{ __html: receivedData.message }}></p>
                            {/* {receivedData.message} */}
                        </div>
                    </div>

                    <div className="login-link">
                        <p>Already have an account? <Link to="/Login">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationComplete