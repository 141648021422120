import React, { useState } from 'react';
import openEye from '../../Resource/openeye.jpeg';
import closeEye from '../../Resource/closeeye.jpeg';


function RegistrationPage3({ formData, setFormData }) {
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    console.log("Form Data :", formData);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const [Country, City, ServerInstanceURL] = formData.RegionName.split('|');
    return (
        <div>
            <div className="form-data-summary scrollable-section">
                <div className="summary-item row">
                    <div className="summary-item">Account Type: <b>{formData.AccountType}</b>  {formData.AccountType === 'Enterprise' && (
                        <b>{formData.CustomerName}</b>
                    )}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">Name: {formData.FirstName} {formData.LastName}</div>

                </div>
                <div className="summary-item row">
                    <div className="summary-item">Address: {formData.CustomerAddress}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">Country: {formData.Country}</div>
                    <div className="summary-item">DC Region: {Country} {City}</div>
                </div>
                {/* <div className="summary-item row">
                    <div className="summary-item">System Time Zone: {formData.timezone}</div>
                </div> */}
            </div>

            <div className="form-row-pagethree">
                <input type="email" placeholder="Enter Email" value={formData.EmailID} onChange={(event) => {
                    setFormData({ ...formData, EmailID: event.target.value })

                }} />

            </div>
            <div className="password-input-container">
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    value={formData.CustomerPassword}
                    onChange={(event) => {
                        setFormData({ ...formData, CustomerPassword: event.target.value })

                    }} />
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    value={formData.confirmpassword}
                    onChange={(event) => {
                        setFormData({ ...formData, confirmpassword: event.target.value })

                    }} />
                <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                    {passwordVisible ? (
                        <img src={openEye} alt="Hide Password" />
                    ) : (
                        <img src={closeEye} alt="Show Password" />
                    )}
                </button>
            </div>

        </div >
    )
}

export default RegistrationPage3