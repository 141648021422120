import React from 'react';
import './LoadingPopup.css'; // Import your CSS styles
import logo from '../Resource/LoaderSide.gif';

const LoadingPopup = ({ message }) => {
    return (
        <div className="modal">

            <img src={logo} className="nospinnerlogo" alt="load...." />
            {/* <div className="loading-text">{message}</div> */}

        </div>

    );
};

export default LoadingPopup;
