import React from 'react';
import successImage from '../../Resource/Verified.png';  // Import your success image
import errorImage from '../../Resource/AccessDenied.png';  // Import your error image
import '../Registration/VerificationCompleted.css';
import { Link } from 'react-router-dom';
import logo from '../../Resource/logo.png';
import { useLocation } from 'react-router-dom';


const VerificationCompleted = () => {
    const location = useLocation();
    const { state } = location;
    const { message, reason } = state || {};
    const imageUrl = message === 'success' ? successImage : errorImage;
    const verificationTitle = message === 'success' ? 'Verification Completed' : 'Verification Failed';
    return (
        <div className="verification-completed-page">
            <div className="completed-card">
                <div className="completed-card-left">
                    <img src={imageUrl} alt="" className="completed-image" />
                    <Link to="/"><img src={logo} alt="Logo" className="logo" /></Link>
                </div>
                <div className="completed-card-right">
                    <h2 className="verification-title">{verificationTitle}</h2>
                    <div className="verification-group">
                        <div className="completed-wrapper">
                            <p dangerouslySetInnerHTML={{ __html: reason }}></p>
                        </div>
                    </div>

                    <div className="verification-link">
                        <p>Already have an account? <Link to="/Login">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationCompleted;


