import React from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Title from './Components/Title/Title';
import ServiceSection from './Components/OurServices/ServiceSection';
import ContactUs from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer';
import WhyUs from './Components/WhyUs/WhyUsSection';
import Mission from './Components/Mission/Mission';
// Import the AlertProvider

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Title text="About Innodhee" />
      <section id="about-section">
        <About />
      </section>

      <Title text="Our Services" />
      <section id="services-section">
        <ServiceSection />
      </section>

      <Title text="Why Choose Innodhee Solutions?" />
      <section id="whyus-section">
        <WhyUs />
      </section>

      <Title text="Mission" />
      <section id="mission-section">
        <Mission />
      </section>
      {/* <Title text="Testimonials" />
      <section id="testimonials-section">
        <TestimonialSection />
      </section> */}
      <Title text="Contact Us" />
      <section id="contact-section">
        <ContactUs />
      </section>
      <Footer />

    </div>
  );
}

export default App;
