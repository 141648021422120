import React, { createContext, useContext, useState } from 'react';
import Alert from './Alert'; // Import the Alert component

// Create the context
const AlertContext = createContext();

// Custom hook to use the alert context
export const useAlert = () => useContext(AlertContext);

// Create the provider component
export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({
        message: '',
        type: '',
        isVisible: false,
        duration: 3000,
    });

    // Function to show an alert
    const showAlert = (message, type = 'success', duration = 3000) => {
        setAlert({ message, type, isVisible: true, duration });
    };

    // Function to close the alert
    const closeAlert = () => {
        setAlert((prev) => ({ ...prev, isVisible: false }));
    };

    return (
        <AlertContext.Provider value={{ showAlert, closeAlert }}>
            {children}
            {/* Conditionally render the Alert component */}
            {alert.isVisible && (
                <Alert
                    message={alert.message}
                    type={alert.type}
                    duration={alert.duration}
                    onClose={closeAlert}
                />
            )}
        </AlertContext.Provider>
    );
};
