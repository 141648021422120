import React from 'react';
import './Hero.css';
import animatedImage from '../../Resource/back-ground.svg'; // Add your animated image here
import automationImage from '../../Resource/innodhee_auto.gif'; // Add your animated image here
import automationImg from '../../Resource/automation.gif'; // Add your animated image here

import Title from '../Title/Title';

const Hero = () => {
    return (
        <section className="hero" id="hero-section">
            <div className="hero-content">
                <div className="hero-text">
                    <Title text="Enhance Your SAP Experience with Innodhee Solutions" />
                    <h2>Data is key to business decisions, and integration is the backbone of digital transformation, powered by seamless API connections.</h2>
                    <h2>Innodhee Solution offers industry-leading solutions for your integrated business processes.</h2>
                    <button className="hero-btn">Let's Get Started</button>
                </div>
                <div className="hero-image">
                    <img src={automationImg} alt="Animated" />
                </div>
            </div>
        </section>
    );
};

export default Hero;
