import bcrypt from 'bcryptjs';



// Reusable function to compare password and hash
export const comparePassword = async (plainPassword, hashedPassword) => {
    try {
        const isMatch = await bcrypt.compare(plainPassword, hashedPassword);
        return isMatch;
    } catch (error) {
        console.error('Error comparing password:', error);
        throw new Error('Failed to compare password');
    }
};

export const getUTCDateTime = () => {
    const now = new Date();

    // Get the various components of the date
    const year = now.getUTCFullYear();
    const month = ('0' + (now.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + now.getUTCDate()).slice(-2);
    const hours = ('0' + now.getUTCHours()).slice(-2);
    const minutes = ('0' + now.getUTCMinutes()).slice(-2);
    const seconds = ('0' + now.getUTCSeconds()).slice(-2);

    // Format the date and time
    const utcDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return utcDateTime;
};


export const generateRandomString = (length = 8) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
};


// Function to generate a long random hexadecimal string
export const generateActivationCode = (length) => {
    const randomBytes = new Uint8Array(length); // Create an array of random bytes
    window.crypto.getRandomValues(randomBytes); // Fill the array with cryptographically secure random values

    // Convert bytes to hexadecimal string
    const hexString = Array.from(randomBytes)
        .map(byte => byte.toString(16).padStart(2, '0')) // Convert each byte to hex and ensure 2-digit format
        .join('');

    return hexString;
};

// Reusable function to hash a password
export const hashPassword1 = async (password) => {
    try {
        const saltRounds = 15;  // You can adjust the salt rounds for more security, but 10 is a good default
        const hashedPassword = await bcrypt.hash(password, saltRounds);
        return hashedPassword;
    } catch (error) {
        console.error('Error hashing password:', error);
        throw new Error('Failed to hash the password');
    }
};

export const hashPassword = async (inputString) => {
    try {
        const encoder = new TextEncoder();
        const data = encoder.encode(inputString);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);

        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        console.log('Hash String :', hashHex);
        return hashHex;
    } catch (error) {
        console.error('Error hashing password:', error);
        throw new Error('Failed to hash the password');
    }
};

