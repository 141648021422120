import React from 'react';
import './Title.css';

const Title = ({ text }) => {
    return (
        <h1 className="title-text">{text}</h1>
    );
};

export default Title;
