import React from 'react'
import useCheckAuth from '../../ProHelpers/CheckAuth'

function Dashboard() {
    useCheckAuth();
    const CustomerID = sessionStorage.getItem('CustomerID');
    const EmailID = sessionStorage.getItem('EmailID');
    const UserName = sessionStorage.getItem('UserName');
    const AccountType = sessionStorage.getItem('AccountType');
    return (
        <div>
            Welcome, {UserName} <br></br>
            Account Type: {AccountType}<br></br>
            CustomerID : {CustomerID}
        </div>
    )
}

export default Dashboard