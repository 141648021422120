import React from 'react';
import './Mission.css';
import missionImage from '../../Resource/mission.jpg'; // Update with your image path

const Mission = () => {
    return (
        <section className="mission-card-container">
            <div className="mission-card">
                <div className="mission-image">
                    <img src={missionImage} alt="Mission" />
                </div>
                <div className="mission-content">
                    <p>
                        Innodhee Solutions aims to empower businesses by simplifying and streamlining their
                        IT operations through effective integration and automation solutions.
                        The company’s mission is to enable organizations to focus on growth and innovation by reducing the complexities of managing diverse IT systems.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Mission;
